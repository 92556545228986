
@font-face {
    font-family: copperplate;
    src: url(../font/Copperplate.woff);
}

*{
    margin: 0;
    padding: 0;
}

.dashboard-logo{
    font-family: 'copperplate' ;
}

.MuiFormControlLabel-label.Mui-disabled{
    color: #63656E !important;
}

.Mui-checked,
.MuiRadio-colorPrimary,
.MuiCheckbox-colorPrimary {
    color: #00b9c6 !important;
}

.isBillingItemSelected{
    background-color: #00B9C6;
}

.isBillingItemSelected *{
    color: #fff !important;
}

.MuiMultiSectionDigitalClock-root ul li.Mui-selected{
    background-color: #00b9c6 !important;
}

.MuiMultiSectionDigitalClock-root ul.MuiMultiSectionDigitalClock-root{
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.MuiMultiSectionDigitalClock-root ul.MuiMultiSectionDigitalClock-root::-webkit-scrollbar{
    display: none;
}

.desktop_time_picker label{
    color: #161F30 !important;
}

.desktop_time_picker fieldset{
    border: none ;
}

.desktop_time_picker.error_time fieldset{
    border:1px solid #FF3D57 !important;
}

.MuiPickersLayout-actionBar button{
    background-color: #00b9c6 !important;
}

.EmojiPickerReact .epr-skin-tones{
    display: none !important;
}

.waba-templates-list-textarea{
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    border: none !important;
    resize: none !important;
    outline: none;
    color: #173540;
    font-size: 14px;
    background-color: transparent !important;
    line-height: 20px;
}

.mr-contact-list-unread-text{
    opacity: 1;
    z-index: 2;
    font-size: 11px;
    background-color: #18D868 !important;
    width: 23px;
    height: 23px;
    display: grid;
    place-items: center;
    color: #fff;
    padding: 0;
}

.reminder-dialog fieldset{
    border-color: rgba(0, 0, 0, 0.23) !important;
}

.reminder-dialog .MuiSelect-select,
.reminder-dialog .MuiOutlinedInput-root input{
    padding: 10px 14px !important;
}

.reminder-setting-area{
    margin-left: 100px;
    margin-top: 50px;
}

.reminder-setting-area label.Mui-disabled{
    opacity: .4;
}

.reminder-setting-area fieldset{
    border-color: rgba(0, 0, 0, 0.23);
}

.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #FF3D57 !important;
}

.MuiFormHelperText-root{
    color: #FF3D57 !important;
}

[sidenavmode="mobile"],
[sidenavmode="full"],
[sidenavmode="compact"]{
    overflow: hidden !important;
}

[sidenavmode="compact"] .side-navigations-area{
    padding-bottom: 180px !important;
}

th{
    white-space: initial !important;
    overflow-wrap: anywhere !important;
}

.navigation-tab-1{
    padding-bottom: 100px !important;
}

.per-dialoge-full {
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .EmojiPickerReact{
        height: 280px !important;
        width: 260px !important;
    }

    .EmojiPickerReact .epr-preview{
        display: none !important;
    }

    .EmojiPickerReact .epr-header-overlay{
        padding: 0 !important;
    }

    .reminder-setting-area{
        margin: 10px;
        margin-top: 50px;
    }
}

@media screen and (max-width:740px) {
    .reminder-item-containers{
        flex-direction: column-reverse;
        gap: 10px;
    }

    .reminder-item-status{
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}

@media screen and (max-width: 600px){
    .reminder-dialog .preview-area{
        margin-top: 20px;
    }
}